import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import styled from 'styled-components';
import { standardColours, brandColours, standardTransition } from '../styles';
import { Slider } from './ui';

const StyledProductGallery = styled.section``;

const StyledImages = styled(Slider)`
  overflow-y: visible !important;

  .swiper-container {
    width: 100%;
  }

  .swiper-pagination {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    padding-top: 8px;
  }

  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background-color: ${standardColours.lightGrey};
    opacity: 1;
    transition: ${standardTransition('background-color')};
    margin: 12px !important;
  }

  .swiper-pagination-bullet-active {
    background-color: ${brandColours.quaternary};
  }

  .swiper-slide {
    text-align: center;
    width: auto;
    height: calc(100%) !important;
  }

  .swiper-wrapper {
    flex-direction: row !important;
  }
`;

const StyledImage = styled(GatsbyImage)`
  pointer-events: none;
`;

const ProductGallery = ({ images, title }) => {
  const sliderOptions = {
    modules: [Autoplay, Pagination],
    slidesPerView: 1,
    loop: true,
    pagination: { clickable: true },
    autoplay: {
      delay: 5000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },
    speed: 1000,
  };

  return (
    images && (
      <StyledProductGallery>
        <StyledImages {...sliderOptions}>
          {images.map(
            (
              {
                preview: {
                  image: { gatsbyImageData, altText },
                },
              },
              i
            ) => (
              <StyledImage
                key={i}
                image={gatsbyImageData}
                alt={altText || `${title} Image ${i + 1}`}
              />
            )
          )}
        </StyledImages>
      </StyledProductGallery>
    )
  );
};

export default ProductGallery;
