import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Container, Grid } from '../components/ui';
import Layout from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import ProductGallery from '../components/ProductGallery';
import ProductDetails from '../components/ProductDetails';
import GrowsWellTogether from '../components/GrowsWellTogether';
import Accordion from '../components/Accordion';
import RelatedProducts from '../components/RelatedProducts';
import IconCtas from '../components/IconCtas';

const ProductPageTemplate = ({
  data: {
    shopifyProduct: {
      storefrontId,
      legacyResourceId,
      title,
      handle,
      images,
      options,
      descriptionHtml,
      seo,
    },
    allShopifyProductVariant: { variants },
    googleReviews,
    datoCmsProduct,
    crossSellProducts,
    growsWellTogether,
    potUpsells: potUpsellsUnordered,
    relatedProducts,
    relatedProductsFallback,
    datoCmsProductSitewide: { accordionItems },
    datoCmsSite: {
      globalSeo: { titleSuffix },
    },
  },
  pageContext: { potUpsells: potUpsellsOrder },
}) => {
  const [selectedVariant, setSelectedVariant] = useState(variants[0]);

  const potUpsellsOrdered = potUpsellsUnordered.nodes.sort(
    (a, b) =>
      potUpsellsOrder.indexOf(a.handle) - potUpsellsOrder.indexOf(b.handle)
  );

  const potUpsells = potUpsellsOrdered.map(defaultPotUpsell => {
    const potUpsell = datoCmsProduct.potUpsellGroup.potUpsells.find(
      ({ shopifyProduct }) => shopifyProduct === defaultPotUpsell.handle
    );

    return {
      ...defaultPotUpsell,
      ...potUpsell,
    };
  });

  return (
    <Layout>
      <HelmetDatoCms>
        <title>{`${seo.title || title}${titleSuffix}`}</title>
        {seo.description && (
          <meta name="description" content={seo.description} />
        )}
      </HelmetDatoCms>
      <main>
        <Container>
          <Breadcrumbs
            breadcrumbs={[{ slug: 'shop', text: 'Shop' }]}
            currentPageTitle={title}
          />
          <Grid>
            <ProductGallery
              images={images}
              title={title}
              selectedVariantImage={selectedVariant.image}
            />
            <ProductDetails
              storefrontId={storefrontId}
              legacyResourceId={legacyResourceId}
              title={title}
              handle={handle}
              googleReviews={googleReviews}
              shortDescription={
                datoCmsProduct && datoCmsProduct.shortDescription
              }
              careIcons={datoCmsProduct && datoCmsProduct.careIcons}
              options={options}
              variants={variants}
              selectedVariant={selectedVariant}
              setSelectedVariant={setSelectedVariant}
              potUpsells={potUpsells}
              crossSellProducts={crossSellProducts.nodes}
            />
          </Grid>
        </Container>
        <GrowsWellTogether items={growsWellTogether.nodes} />
        <Accordion
          heading="Useful Information"
          hideHeading={true}
          items={[
            { heading: 'Plant Bio', content: descriptionHtml },
            ...accordionItems,
          ]}
          isProductPage={true}
        />
        <RelatedProducts
          items={
            (relatedProducts.nodes.length > 0 && relatedProducts.nodes) ||
            (relatedProductsFallback.nodes.length > 0 &&
              relatedProductsFallback.nodes)
          }
        />
        <IconCtas isProductPage={true} />
      </main>
    </Layout>
  );
};

export const ProductPageTemplateQuery = graphql`
  query ProductPageTemplateQuery(
    $id: String!
    $handle: String!
    $crossSellProducts: [String]!
    $growsWellTogether: [String]!
    $potUpsells: [String]
    $relatedProducts: [String]
    $firstCollectionId: String
  ) {
    shopifyProduct(id: { eq: $id }) {
      storefrontId
      legacyResourceId
      title
      handle
      images: media {
        id
        preview {
          image {
            gatsbyImageData(width: 560, height: 680)
            altText
          }
        }
      }
      options {
        name
      }
      descriptionHtml
      seo {
        title
        description
      }
    }
    allShopifyProductVariant(
      filter: { _product: { eq: $id } }
      sort: { fields: availableForSale, order: DESC }
    ) {
      variants: nodes {
        id
        storefrontId
        legacyResourceId
        title
        sku
        price
        compareAtPrice
        image: media {
          id
        }
        metafields {
          key
          value
        }
      }
    }
    googleReviews: googleReview {
      averageRating
      totalReviewsCount
    }
    datoCmsProduct(shopifyProduct: { eq: $handle }) {
      shortDescription
      careIcons {
        url
        alt
      }
      potUpsellGroup {
        potUpsells {
          name
          description
          shopifyProduct
        }
      }
    }
    crossSellProducts: allShopifyProduct(
      filter: { handle: { in: $crossSellProducts } }
    ) {
      nodes {
        title
        featuredImage {
          gatsbyImageData(width: 180, height: 180)
          altText
        }
        variants {
          storefrontId
          price
        }
      }
    }
    growsWellTogether: allShopifyProduct(
      filter: { handle: { in: $growsWellTogether } }
    ) {
      nodes {
        ...ProductCardFragment
      }
    }
    potUpsells: allShopifyProduct(filter: { handle: { in: $potUpsells } }) {
      nodes {
        handle
        variants {
          storefrontId
          price
          availableForSale
          selectedOptions {
            name
            value
          }
          metafields {
            key
            value
          }
        }
      }
    }
    relatedProducts: allShopifyProduct(
      filter: { handle: { in: $relatedProducts } }
    ) {
      nodes {
        ...ProductCardFragment
      }
    }
    relatedProductsFallback: allShopifyProduct(
      filter: {
        id: { ne: $id }
        collections: { elemMatch: { id: { eq: $firstCollectionId } } }
        totalInventory: { gt: 0 }
      }
      limit: 8
    ) {
      nodes {
        ...ProductCardFragment
      }
    }
    datoCmsProductSitewide {
      accordionItems {
        id
        heading
        content
      }
    }
    datoCmsSite {
      globalSeo {
        titleSuffix
      }
    }
  }
`;

export default ProductPageTemplate;
